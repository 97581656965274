import React from 'react'
import classNames from 'classnames'
import IProps from './interface'

const Button = (props: IProps) => {
  const {
    type = 'primary',
    color = 'primary',
    size = 'base',
    fullWidth = false,
    wrappable = false,
    animated = false,
    dataType,
    id,
    customAttributes,
    customClasses,
    icon = false,
    ignoreSectionThemes,
    children,
  } = props

  return (
    <button
      {...(id ? { id } : {})}
      {...(dataType ? { 'data-type': dataType } : {})}
      {...(customAttributes ? customAttributes : {})}
      className={classNames(
        {
          ['button']: true,
          [`button__color--${color}`]: color,
          [`button__type--${type}`]: type,
          [`button__size--${size}`]: size,
          [`button__animated`]: animated,
          ['button__fullWidth']: fullWidth,
          ['button__wrappable']: wrappable,
          ['button__ignoreSectionThemes']: ignoreSectionThemes,
          ['button__icon']: icon,
        },
        customClasses || ''
      )}>
      {children}
    </button>
  )
}

export default Button
