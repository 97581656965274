import React, { useState } from 'react'
import BlockWrapper from '../../../Layout/BlockWrapper/BlockWrapper'
import Title from '../../../Element/Title/Title'
import IProps, { ISearchInput } from './interface'
import Button from '../../../Element/Button/Button'

const SearchInput = ({ placeholder, baseUrl }: ISearchInput) => {
  const [query, setQuery] = useState('')

  const disabled = query.trim().length < 3

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (disabled) return
    // Redirect to the search results page with the query as a parameter
    location.href = `${baseUrl}?term=${encodeURIComponent(query)}`
  }

  // Implement the search input component here
  return (
    <form onSubmit={handleSubmit} className='inlineSearchPrompt__form'>
      <label htmlFor='search' className='sr-only'>
        Search
      </label>
      <input
        type='text'
        placeholder={placeholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className='inlineSearchPrompt__input'
      />
      <Button
        type='primary'
        icon
        color='primary'
        size='base'
        customClasses='inlineSearchPrompt__submit'
        customAttributes={{
          disabled: disabled,
          type: 'submit',
        }}>
        <i className='icon'>search</i>
      </Button>
    </form>
  )
}

const InlineSearchPrompt = (props: IProps) => {
  const { placeholder, baseUrl, title, ...rest } = props

  return (
    <BlockWrapper {...rest}>
      <Title type={title.type} classes='inlineSearchPrompt__title'>
        {title.text}
      </Title>
      <SearchInput placeholder={placeholder} baseUrl={baseUrl} />
    </BlockWrapper>
  )
}
export default InlineSearchPrompt
