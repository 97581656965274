import React from 'react'
import classNames from 'classnames'

import IProps from './interface'

/**
 * -- Title element: A title element that can be used to display text.
 */

const Title = (props: IProps) => {
  const {
    id,
    type,
    children,
    classes,
    leftIndent,
    rightIndent,
    dynamic,
    customAttributes,
  } = props

  const className = classNames(classes, {
    [`text-indent-left-${leftIndent}`]: leftIndent && leftIndent !== 'none',
    [`text-indent-right-${rightIndent}`]: rightIndent && rightIndent !== 'none',
  })

  if (!children) return null

  if (dynamic) {
    return (
      <div data-type='dynamic-text-wrapper'>
        {React.createElement(
          type,
          {
            ...customAttributes,
            className,
            id,
            'data-type': 'dynamic-text',
          },
          children
        )}
      </div>
    )
  }

  return React.createElement(
    type,
    { className, id, ...customAttributes },
    children
  )
}

export default Title
