import React from 'react'
import classNames from 'classnames'

import { IBlockWrapperProps } from './interface'

const BlockWrapper = (props: IBlockWrapperProps) => {
  const {
    children,
    id = undefined,
    name = 'anonymous',
    topPadding,
    bottomPadding,
    leftIndent,
    rightIndent,
    bleed,
    scrollIntoViewTransitions = true,
    sectionTheme = 'none',
    dataType = undefined,
  } = props

  const className = classNames(
    { [name]: true },
    { [`pb-${bottomPadding}`]: bottomPadding },
    { [`pt-${topPadding}`]: topPadding },
    { 'indent-bleed': bleed },
    { [`indent-left-${leftIndent}`]: leftIndent && !bleed },
    { [`indent-right-${rightIndent}`]: rightIndent && !bleed }
  )

  return (
    <div
      id={id}
      className={className}
      data-sectiontheme={sectionTheme}
      data-scrollintoview={scrollIntoViewTransitions ? 'fadeInUp' : undefined}
      data-type={dataType}>
      {children || <div>Nothing to display</div>}
    </div>
  )
}

export default BlockWrapper
